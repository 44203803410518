
.tileWrapper{
    width: 220px;
    height: 330px;
    text-align: center;
    background-color: rgba(255, 255, 255, 1);


    margin-right: 20px;
    margin-bottom: 30px;
    
    transition: transform 450ms;

    cursor: pointer;

   
}

.tileWrapper:hover{
    transform: scale(1.04);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);

}

.imageHeader{
    width: 100%;
   height: 43%;
   object-fit: cover;
}

.tekstContainer{
    padding: 17px;
    cursor: pointer;
}

.description{
    font-size: 15px;
    line-height: 17px;
    color: var(--primarycolor);
    font-family: 'GT-Walsheim-Medium';
     cursor: pointer;
}


@media screen and (max-width: 800px) {

    .tileWrapper{
        width: 100%;
        height: 330px;
        text-align: center;
        max-width: 230px;
    
        margin-right: 30px;
        margin-bottom: 30px;
        
        transition: transform 0;
        
    
       
    }
    
    .tileWrapper:hover{
        transform: none;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    
    }
   
.imageHeader{
    width: 230px;
}


    

}