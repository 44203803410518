
.titleContainerCenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 4%;
}

.startButtonCenter{
    width: 300px;
    height: 50px;
    background-color: var(--primarycolor);
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-family: 'GT-Walsheim-Medium';
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 0px 25px -0px rgba(0,0,0,0.20);
    cursor: pointer;
}


.pressEnterContainerCenter{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.enterText{
    font-size: 15px;
    color: black;
    font-family: 'GT-Walsheim-Medium';
}

.enter{
    width: 10px;
    height: 10px;
}

.padding15{
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 800px) {
    .padding15{
        padding-left: 0%;
    }


.titleContainerCenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; 
    padding-bottom: 30px;
}

.startButtonCenter{
    width: 200px;
        height: 55px;
        background-color: white; 
        color:var(--primarycolor) ;
    
        display: flex;
       justify-content: center;
       flex-direction: column;
       align-items: center;
    
       font-size: 18px;
       font-family: 'GT-Walsheim-Medium';
       -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
    
    
       user-select: none;
       cursor: default;
    
       transition:   background-color 0.2s;


}

.startButtonCenter:hover{
    background-color: var(--primarycolor) ; 
    color:white;


}

}