

.chromeMessage{
  background-color:var(--primarycolor);
  color:var(--secondaryyellow);
  width: 100vw;
  height: 35px;
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0;
  justify-content:center;
  align-items:center;
  font-size: 14px;
  font-family: 'GT-Walsheim-Medium';
  cursor: default;


}


/* General Styling */

body{
  scrollbar-width: none;
  background-color: var(--background-color);

}


:root {
  --background-color: rgba(244,244,244,1);
  --primarycolor: #23285a;
  --secondaryyellow: #fdc300;
} 

h1{
  font-size: 40px;
  color: var(--primarycolor);
  padding: 0;
  margin: 0;
  font-family: 'GT-Walsheim-Medium';
  cursor: default;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

}

h2{
  font-size: 30px;
  color: var(--primarycolor);
  padding: 0;
  margin: 0;
  font-family: 'GT-Walsheim-Medium';
  cursor: default;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */


}

h3{
  font-size: 20px;
  color: var(--secondaryyellow);
  margin: 0;
  padding: 0;
  cursor: default;
  font-family: 'GT-Walsheim-Medium';

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

h4{
  font-size: 16px;
  color: var(--secondaryyellow);
  margin: 0;
  padding: 0;
  font-family: 'GT-Walsheim-Medium';
  text-transform: uppercase;
  cursor: default;
  margin-bottom: -10px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

h5{
  font-size: 23px;
  color: var(--primarycolor);
  padding: 0;
  margin: 0;
  margin-bottom: -10px;
  cursor: default;
  font-family: 'GT-Walsheim-Bold';

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

a{
  color: black;
  cursor: pointer;
  font-family: 'GT-Walsheim-Bold';

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

p{
color: var(--primarycolor);
  font-family: 'GT-Walsheim-Regular';
  cursor: default;
  font-size: 16px;
  letter-spacing: 0px;
  word-spacing: 1px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.noselect {
-webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.preloadFont1{
  font-family: 'GT-Walsheim-Regular';
}
.preloadFont2{
  font-family: 'GT-Walsheim-Bold';
}
.preloadFont3{
  font-family: 'GT-Walsheim-Medium';
}

/* Fade in effect */


.fadeIn-enter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.fadeIn-enter-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 750ms;
}
.fadeIn-exit {
  opacity: 1;
  width: 100%;
}
.fadeIn-exit-active {
  opacity: 0;
  width: 100%;
  transition: opacity 750ms;
}


.fadeInLater-enter {
  opacity: 0;
}

.fadeInLater-enter-active {
  opacity: 1;
  transition: opacity 750ms;
}
.fadeInLater-exit {
  opacity: 1;
}
.fadeInLater-exit-active {
  opacity: 0;
  transition: opacity 750ms;
}

.fadeInEndScreen-enter {
  opacity: 0;
}

.fadeInEndScreen-enter-active {
  opacity: 1;
  transition: opacity 750ms, background-color 750ms;
}
.fadeInEndScreen-exit {
  opacity: 1;
}
.fadeInEndScreen-exit-active {
  opacity: 0;
  transition: opacity 750ms;
}

/* Main Wrappers styling */

.mainWrapper{
  width: 100vw;
  height: 100vh;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);
  background-image: url(./Assets/Images/RiskBackground.png); 
  background-size: cover;
  overflow-y: hidden;
}

.mainWrapperRouter{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  background-size: cover;
  background-position: center;
  overflow-y: hidden;
}

.mainWrapperEndScreen{
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondaryyellow);
  background-image: url(./Assets/Images/RiskBackground.png); 
  background-repeat: no-repeat;
  background-size: cover;

  background-position: left;
}

.mainWrapperEndScreen::-webkit-scrollbar{
  display: none;
  scrollbar-width: none;
 }

 /* Content Wrappers styling */


.Screen{
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.essentialWrapper{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  overscroll-behavior: none;
  scrollbar-width: none;
  
}

.essentialWrapper::-webkit-scrollbar{
  display: none;
  scrollbar-width: none;
}


.contentWrapperEnd{
  height: 100%;
  width: 70%;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
  padding-left: 9%;
  padding-right: 10%;
  scrollbar-width: none;
  left: 0;
}


.contentWrapperEnd::-webkit-scrollbar{
display: none;
scrollbar-width: none;
}




.fullwidthWrapper{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overscroll-behavior: none;
  scrollbar-width: none;
}


.contentWrapperInput{
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 9%;
} 


.contentWrapperChoice{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  overflow: visible;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding-left: 9%;
}

.contentWrapperChoice::-webkit-scrollbar{
  display: none;
  scrollbar-width: none;
  
}

.contentWrapperRow{
  width: 80%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding-left: 9%;
}


.choicesWrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}


.selectContainer{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;


}

/* Elements Styling */


.endDot{
  width: 1px;
  margin-left: 5px;
  color: var(--background-color);
  visibility: hidden;
}


/* Mobile styling */

@media screen and (max-width: 800px) {


/* General styling */
  body{
    background-color: var(--background-color);
  }

  .mobileLeft{
    padding-left: 10%;
    padding-right: 10%;
  }

  h1{
    font-size: 28px;
    line-height: 35px;
  }

  h2{
    font-size: 21px;
  }

  h3{
    font-size: 16px;
  }

  h4{
    font-size: 15px;
  }

  h5{
    font-size: 20px;
  }

  a{
    color: black;
    font-family: 'GT-Walsheim-Bold';
    margin-left: 2px;

  }

  p{
    color: var(--primarycolor);
    font-family: 'GT-Walsheim-Regular';
    font-size: 16px;
  }


  /* Main Wrapper Styling  */
  .mainWrapper{
    width: 100vw;
    height:90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color);
    background-image: url(./Assets/Images/RiskBackgroundMobile-51.png); 
    overflow-y: visible;
    overscroll-behavior: none;
  }

  .mainWrapper::-webkit-scrollbar{
    display: none;
    scrollbar-width: none;
  }


  .mainWrapperEndScreen{
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondaryyellow);
    background-image: url(./Assets/Images/RiskBackgroundMobile-51.png);
    background-size: cover;
    overflow-y: hidden;
    overflow-x: hidden;
    overscroll-behavior: auto;
  }

  .mainWrapperEndScreen::-webkit-scrollbar{
    display: none;
    scrollbar-width: none;
  }

  /* Content Wrappers */


.essentialWrapper{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  overscroll-behavior: none;
  padding-top: 0%;
  padding-bottom: 0%;
}



  .contentWrapperChoice{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding-top: 7%;
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;

  }


.contentWrapperInput{
 
  padding-left: 0;
  padding-right: 0;
} 


 .selectContainer{
  width: 100%;
  height: 100%;
  padding-top: 5%;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
  overscroll-behavior-x: contain;
  padding-left: 10%;

 
}
.selectContainer::-webkit-scrollbar{
  display: none;
  scrollbar-width: none;
}


  .contentWrapperRow{
    width: 80%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }
  .contentWrapperEnd{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    overflow-x: hidden;
    overflow-y: scroll;
    overscroll-behavior: auto;
    padding-left: 0;
    padding-right: 0;
    scrollbar-width: none;
  }
 
  .Screen{
    width: 100%;
    height: 90%;
  }

  .endDot{
    width: 50px;
    margin-left: 100px;
    color: var(--background-color);
  }



.fadeInLater-enter {
  opacity: 0;
}
.fadeInLater-enter-active {
  opacity: 1;
  transition: opacity 750ms;
}
.fadeInLater-exit {
  opacity: 1;
}
.fadeInLater-exit-active {
  opacity: 0;
  transition: opacity 750ms;
}



}





/* Logo for loading */
.logoWrapper{
  height: 210px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.25);
  border-radius: 110px;
  background-color: var(--primarycolor);
}

.logoWrapperFinished{
  height: 210px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.25);
  border-radius: 110px;
  background-color: var(--primarycolor);
}

.logoWrapperContainer{
  width:110px;
  height: 110px;
}

.excalamtionWrapper{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 33%;
}

.excalamtionWrapperReverse{
  display: flex;
  flex-direction: row;
  width: '100%';
  justify-content: space-between;
  align-items: center;
  height: 33%;
  transform: rotate(180deg);
}

.logoBarWrapper{
  display: grid;
  justify-items: center;
  align-items: center;
  width: 70%;
  height: 100%;
}

.logoBar{
  width: 90%;
  height: 20px;
  background-color: rgb(255,255,255);
  overflow: hidden;
}

.logoDotWrapper{
  display: grid;
  justify-items: center;
  align-items: center;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.logoDot{
  width: 30px;
  height: 30px;
  background-color: rgb(255,255,255);
  border-radius: 75px;
  overflow: hidden;
}

.barFiller-enter {
  width: 0px;
  background-color: var(--secondaryyellow);
}
.barFiller-enter-active {
  width: 100%;
  transition: width 750ms;
}
.barFiller-exit {
  width: 200px;
}
.barFiller-exit-active {
  width: 0px;
  transition: width 750ms;
}
.FilledBar{
  height: 100%;
}
