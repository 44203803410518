.titleContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 30px;
    flex-wrap: wrap;
} 


@media screen and (max-width: 800px) {
    .titleContainer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-bottom: 10px;

    } 

}