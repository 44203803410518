.check {
  width: 300px;
  height: 200px;
}

.bottomSectionContainer {
}

.succesButtonContainer {
  display: flex;
  padding-top: 30px;
}

.marginRight {
  margin-right: 30px;
}

@media screen and (max-width: 800px) {
  .succesImageContainer {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;

    padding-bottom: 30px;
  }
  .bottomSectionContainer {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 40%;
  }

  .succesButtonContainer {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }

  .marginRight {
    margin-right: 0px;
    margin-bottom: 20px;
  }

}
