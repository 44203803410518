.contentContainerEndscreen {
  width: 100%;
  max-width: 1250px;
  
}

.contentWrapperCommittees {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}

.contentWrapperCommittees::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.committeeTileWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding-bottom: 50px;
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

@media screen and (max-width: 800px) {
  .committeeTileWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-top: 5%;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
    overscroll-behavior-x: contain;
  }
  .committeeTileWrapper::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }
}
