.spotlightTile{
    width: 100%;
    height: 280px;
    background: white;
    
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: space-between;
    
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    
    transition: transform 450ms;
    
    }
    .spotlightTile:hover{
        
        transform: scale(1.02);
        
        
        }
    
    .spotlightTextContainer{
        width: 40%;
        padding-left: 5%;
    }
    
    .spotlightImageContainer{
        width: 50%;
        height: 100%;
        }
    
    .spotlightImage{
        width: 100%;
        height: 100%;
    object-fit: cover;
    background-color: grey
    }
    
    .spotlightTextButton{
        width: 75px;
        height: 40px;
        background-color: var(--primarycolor);
    
        color: white;
    
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    
        font-size: 15px;
        font-family: "GT-Walsheim-Medium";
    
        transition: transform 450ms;
        cursor: pointer;
    
    
    }
    
    .spotlightTextButton:hover{
    
    }
    
    .chevron{
        width: 15px;
        height: 15px;
    }


@media screen and (max-width: 800px) {

    .spotlightTile{
        width: 100%;
        height: 480px;
        background: white;
        
        display: flex;
        flex-direction: column-reverse; 
        align-items: center;
        justify-content: space-between;
        
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
        
        transition: transform 450ms;
        
        }
        .spotlightTile:hover{
            
            transform: scale(1.00);
            
            
            }
        
        .spotlightTextContainer{
            height: 50%;
            width: 80%;
            padding-left: 0%;

            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: column;
            justify-content: center;
        }
        
        .spotlightImageContainer{
            width: 100%;
            height: 50%;
            }
        
        .spotlightImage{
            width: 100%;
            height: 100%;
        object-fit: cover;
        background-color: grey
        }
        
    

}
    