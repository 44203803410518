.midSection{
    width: 90%;
}

.midImageContainer{
    margin-bottom: 40px;
    width: 100%;
    height: 400px;
} 

.midImage{
    width: 100%;
    height: 100%;
    object-fit: cover;

    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.20);

}

@media screen and (max-width: 800px) {

    .midSection{
        width: 80%;
    }
    
    .midImageContainer{
        margin-bottom: 40px;
        width: 100%;
        height: 200px;
    }
    
    .midImage{
        width: 100%;
        height: 100%;
        object-fit: cover;
    
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
    
    
    }
    

}