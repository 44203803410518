.buttonContainer{
padding-top: 20px;
}



.pressEnterContainer{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
}

.enterText{
    font-size: 16px;
    color: black;
    font-family: 'GT-Walsheim-Medium';
    margin-right: 10px;
}


.enter{
    width: 20px;
    height: 20px;
    margin-top: 5px;
}
