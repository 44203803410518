
.mobileButton{
    width: 250px;
    height: 55px;
    background-color: white; 
    color:var(--primarycolor) ;

    display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;

   font-size: 19px;
   font-family: 'GT-Walsheim-Medium';
   -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);

   user-select: none;
   cursor: pointer;

   transition:   background-color 0.2s;

}

.mobileButton:hover{
    background-color: var(--primarycolor) ; 
    color:white;
}


@media screen and (max-width: 800px) {

    .mobileButton{
        width: 250px;
        height: 55px;
        background-color: white; 
        color:var(--primarycolor) ;
    
        display: flex;
       justify-content: center;
       flex-direction: column;
       align-items: center;
    
       font-size: 18px;
       font-family: 'GT-Walsheim-Medium';
       -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
    
    
       user-select: none;
       cursor: default;
    
       transition:   background-color 0.2s;
    
    
    }
    
    .mobileButton:hover{
        background-color: var(--primarycolor) ; 
        color:white;
    
    
    }

}

