
.fullwidthTextContainer{
    width: 90%;
  }

  @media screen and (max-width: 800px) {
    .fullwidthTextContainer{
        width: 100%;
      }

  }