.committeeTile{
    width: 270px;
    height: 430px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5%;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    transition: transform 450ms;
}


.committeeTile:hover{
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    transform: scale(1.02);
}

.committeeTextContainer{
    width: 85%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.committeeImageContainer{
    width: 270px;
    height: 40%;
}

.committeeImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: grey
} 

.committeeTextButton{
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: "GT-Walsheim-Medium";
    transition: transform 450ms;
    cursor: pointer;
    background: var(--primarycolor);
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 25px;
    padding-right: 25px;

}

.chevron{
    width: 15px;
    height: 15px;
}


@media screen and (max-width: 800px) {

    .committeeTile{
        margin-right: 40px;
    }
}