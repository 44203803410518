@import './Styles.css';
html{
  overflow: -moz-hidden-unscrollable;
  overflow: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar{
  display: none;
  width: 0px;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'GT-Walsheim-Regular';
  src: local('GT-Walsheim-Regular'), url(./Assets/Fonts/GT-Walsheim-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'GT-Walsheim-Medium';
  src: local('GT-Walsheim-Medium'), url(./Assets/Fonts/GT-Walsheim-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'GT-Walsheim-Bold';
  src: local('GT-Walsheim-Bold'), url(./Assets/Fonts/GT-Walsheim-Bold.ttf) format('truetype');
}