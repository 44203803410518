.inputContainer{
    margin:0;
    padding: 0;
    width: 100%;
}

.inputField{
    width: 100%;
    border: none;
    max-width: 700px;
    border-bottom: 1px black solid;
    background: transparent;
    outline: none;
    padding-bottom: 5px;
    font-size: 30px;
    color: var(--primarycolor);
    font-family: 'GT-Walsheim-Medium';
}

::placeholder {
    color: rgba(35,40,90,0.3);
}


@media screen and (max-width: 800px) {
    .inputContainer{
        margin:0;
        padding: 0;
        width: 90%;
    }
    
    .inputField{
        width: 100%;
        border: none;
        border-bottom: 1px black solid;
        background: transparent;
        outline: none;
        padding-bottom: 3px;
        font-size: 20px;
        color: var(--primarycolor);
        font-family: 'GT-Walsheim-Medium';
    }
    

}

