@import '../../Styles.css';


.progressBarWrapper{
    background-color: rgba(255,255,255,1);
    width: 90px;
    height: 40px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);
    text-align: left;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    
    }
.progressBarContainer{
    width: 90%;
    height: 70%;
    text-align: left;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    font-size: 15px;
    color: var(--primarycolor);
    font-family: 'GT-Walsheim-Medium';
    
    }



.progressBar{
    width: 100%;
    height: 4px;
    background-color: rgba(0,0,0,0.15);
}

.progress{
    height: 4px;
    background-color: var(--primarycolor);
}


@media screen and (max-width: 800px) {

.progressBarWrapper{
    width: 80%;
    height: 40px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);
    text-align: left;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    min-width: 80px;
    margin-right: 10%;
    max-width: 1000px;
    }

}