@import '../../Styles.css';

.navWrapper{
    height: 100vh;
    width: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    scrollbar-darkshadow-color: teal;
    scrollbar-width: 200px;
    right: 0;
    top: 0;
    position: fixed;
}


.logoContainer{
    display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   padding-top: 50px;
}

.logo{
    width: 60px;
    height: 60px;
}

.navigationContainer{
    width: 100%;
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   padding-bottom: 40px;
}

.backbuttonContainer{
background-color: white;
width: 90px;
height: 50px;
-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);

display: flex;
justify-content: center;
flex-direction: column;
color: black;
font-size: 17px;
font-family: 'GT-Walsheim-Medium';
align-items: center;
cursor: pointer;

}

.backbuttonContainer:hover{
    background-color: white;
    -webkit-box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.25);
   
}

.backArrow{
    width: '30px';
    height: '30px';
    color: '#23285a';
}
    

.creditContainer{
    text-align: center;
    flex-direction: column;
    font-size: 11px;
    padding-top: 10px;
    font-family: 'GT-Walsheim-Medium';
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: default;

}



.progressBarWrapper{
    background-color: rgba(255,255,255,0.8);
    width: 90px;
    height: 40px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);
    text-align: left;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    
    }
.progressBarContainer{
    width: 90%;
    height: 70%;
    text-align: left;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    font-size: 15px;
    color: var(--primarycolor);
    font-family: 'GT-Walsheim-Medium';
    
    }


.flexendMobile{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}


.progressBar{
    width: 100%;
    height: 4px;
    background-color: rgba(0,0,0,0.15);
}

.progress{
    width: 80%;
    height: 4px;
    background-color: var(--primarycolor);
}


@media screen and (max-width: 800px) {

    .navWrapper{
        height: 10vh;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 10%;
        padding-right: 10%;
        position: fixed;
        top: auto;
        bottom: 0;
        background-color: var(--background-color);
    }
    
    
    .logoContainer{
        display: flex;
       justify-content: center;
       flex-direction: column;
       align-items: flex-start;
       padding-top: 0px;
       width: 20%;
    }
    
    .logo{
        width: 40px;
        height:40px;
    }
    
    .navigationContainer{
        width: 80%;
       display: flex;
       justify-content: space-between;
       flex-direction: row;
       align-items: center;
       padding-bottom: 0px;
    }
    
    .backbuttonContainer{
    background-color: white;
    height: 30px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 70px;
    
    }
    
    .backbuttonContainer:hover{
        background-color: white;
        -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.15);
    }
    
    .creditContainer{
        font-size: 9px;
        padding: 0;

        font-family: 'GT-Walsheim-Medium';
        display: flex;
        padding-top: 5px;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
    }


.backArrow{
    width: '15px';
    height: '15px';
    color: '#23285a';
}


.progressBarWrapper{
    width: 80%;
    height: 40px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);
    text-align: left;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    min-width: 80px;
    margin-right: 10%;
    max-width: 1000px;

    
    
    }


    

}