
.choiceTile{
    width: 180px;
    height: 65px;
    background-color: white; 
    color:   var(--primarycolor);

    display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   text-align: center;

   font-size: 18px;
   font-family: 'GT-Walsheim-Medium';
   text-transform: uppercase;

   flex-wrap: wrap;

   margin-right: 20px;
   margin-bottom: 20px;
   transition: transform 450ms;

    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
    cursor: pointer;

}

.choiceTile:hover{
    background-color: var(--primarycolor);;
    color: var(--secondaryyellow);   
    transform: scale(1.04);
}

.choiceTile--pressed{
    width: 180px;
    height: 65px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    font-size: 18px;
    font-family: 'GT-Walsheim-Medium';
    text-transform: uppercase;

    margin-right: 20px;
    margin-bottom: 20px;
    transition: transform 450ms;

    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);

    background-color: var(--primarycolor);;
    cursor: pointer;
    color: var(--secondaryyellow);   
}




@media screen and (max-width: 800px) {


.choiceTile{
    width: 47%;
    height: 60px;
    background-color: white; 
    color:   var(--primarycolor);

    display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;

   font-size: 15px;
   font-family: 'GT-Walsheim-Medium';
   text-transform: uppercase;

   margin-right: 2%;
   margin-bottom: 10px;
   transition: transform 450ms;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    cursor: pointer;

}

.choiceTile:hover{
    background-color: var(--primarycolor);;
    color: var(--secondaryyellow);   
    transform: scale(1.0);
}


.choiceTile--pressed{
    width: 47%;
    height: 60px;

    display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;

   font-size: 15px;
   font-family: 'GT-Walsheim-Medium';
   text-transform: uppercase;

   margin-right: 2%;
   margin-bottom: 10px;
   transition: transform 450ms;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    cursor: pointer;

    background-color: var(--primarycolor);;
    color: var(--secondaryyellow);   
}


}