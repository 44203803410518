.topSection{
    width: 90%;
    text-align: left;
    padding-top: 10%;

}
 
.headerImageContainer{
    margin-bottom: 40px;
    width: 100%;
    height: 500px;
}

.headerImage{
    width: 100%; 
    height: 100%;
    object-fit: cover;

    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.20);

}

.textDescription{
  padding-bottom:80px;
   font-size: 20px;
    text-align:left;

}

.topSectionTextContainer{

}


@media screen and (max-width: 800px) {

    .topSection{
        width: 100%;
    text-align: left;
    padding-top: 0%;

    }
    
    .headerImageContainer{
        margin-bottom: 40px;
        width: 100vw;
        height: 260px;
    }
    
    .headerImage{
        width: 100%;
        height: 100%;
        object-fit: cover;
    
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
    
    }
    .textDescription{
        padding-bottom:80px;
         font-size: 17px;
          text-align:left;
        

      
      }


.topSectionTextContainer{
    padding-left: 10%;
    padding-right: 10%;
}
      

}