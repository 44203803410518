.buttonSection{
    width: 100%;

}


.buttonsWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 150px;
}
@media screen and (max-width: 800px) {

.buttonSection{
    width: 100%;

}

.buttonsWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 150px;
}

.buttonOne{
    margin-bottom: 15px;
}
.buttonTwo{
    margin-bottom: 15px;
}
 
}